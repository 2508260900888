import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import Echo from 'laravel-echo';
// import * as Pusher from 'pusher';
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class EchoService {

    public echo: Echo;
    public authEndpoint: string = `${environment.apiRoot}/broadcasting/auth`;

    constructor(
        private http: HttpClient
    ) {
    }

    public authorizer(channel: any) {
        return {
            authorize: (socketId, callback) => {
                this.http.post(this.authEndpoint, {
                    socket_id: socketId,
                    channel_name: channel.name
                }).subscribe(
                    (response: any) => {
                        callback(null, response);
                    },
                    (error) => {
                        callback(error, null);
                    }
                );
            }
        };
    }

    public connect() {
        if (this.echo instanceof Echo) {
            this.echo.connector?.pusher?.connect();
        } else {
            this.echo = new Echo({
                broadcaster: 'pusher',
                key: environment.pusher.key,
                // // @ts-ignore
                // client: new Pusher(environment.pusher.key, {
                //     cluster: environment.pusher.cluster,
                //     authorizer: this.authorizer.bind(this),
                // })
            });
        }
    }

    public disconnect() {
        if (this.echo instanceof Echo) {
            this.echo.disconnect();
        }
    }
}
