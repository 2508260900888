import {filter, map} from "rxjs/operators";
import {Subject} from "rxjs";

export default class GBStorage {

    private static _instance: GBStorage;
    private _events: Subject<{event: string, key?: string, value?: any}> = new Subject();
    private _allEvents: string[] = ["clear"];
    private _DB: Record<string, any> = {};
    private _prefix: string = "gb-db|";

    public static instance(): GBStorage {
        if (!GBStorage._instance) {
            GBStorage._instance = new GBStorage();
        }
        return GBStorage._instance;
    }

    public set(key: string, value: any) {
        this._DB[key] = value;

        try {
            localStorage.setItem(this._prefix + key, JSON.stringify(value));
        } catch (e) {
        }

        this._events.next({event: "set", key, value});
        return true;
    }

    public get(key: string) {
        if (!this._DB?.[key]) {
            try {
                const raw = localStorage.getItem(this._prefix + key);
                this._DB[key] = JSON.parse(raw);
            } catch (e) {
            }
        }
        return this._DB?.[key];
    }

    public remove(key: string) {
        delete this._DB[key];

        try {
            localStorage.removeItem(this._prefix + key);
        } catch (e) {
        }

        this._events.next({event: "remove", key});
        return true;
    }

    public clear() {
        this._DB = {};
        this._events.next({event: "clear"});

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            localStorage.removeItem(key);
        }

        return true;
    }

    public observe(key: string) {
        return this._events
            .pipe(
                filter(data => data.key === key || this._allEvents.includes(data.event)),
                map(data => data?.value)
            );
    }

}
