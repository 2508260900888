import {Injectable, Optional} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {IResponse} from "../interfaces/IResponse";
import {SocialProviders} from "../interfaces/general";
import {Observable, of} from "rxjs";
import {FirebaseAuthentication} from '@robingenz/capacitor-firebase-authentication';
import {take} from "rxjs/operators";
import {LoadingController, ModalController, Platform, ToastController} from "@ionic/angular";
import {IAuthData} from "../interfaces/IAuth";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {IUser} from "../interfaces/IUser";
import {cloneDeep} from "lodash";
import {LoginModal} from "../components/modals/login/login-modal";
import {StorageService} from "./storage.service";
import {Color} from "@ionic/core";
import {
    Auth,
    signInWithRedirect,
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    OAuthProvider,
    signOut, idToken
} from "@angular/fire/auth";

@Injectable({
    providedIn: "root"
})
export class AuthService {

    private loginUrl: string = environment.api + "/auth/login";
    private registerUrl: string = environment.api + "/auth/register";
    private resetPasswordUrl: string = environment.api + "/auth/reset-password";
    private newPasswordUrl: string = environment.api + "/auth/new-password";
    private logoutUrl: string = environment.api + "/auth/logout";
    private connectUrl: string = environment.api + "/auth/connect-partner";

    public loader: any = null;
    public returnUrl: string = null;
    public isSafari: boolean = false;
    public isCapacitor: boolean = false;
    public checkingAuthState: boolean = false;

    constructor(
        private http: HttpClient,
        private platform: Platform,
        private router: Router,
        private modalController: ModalController,
        public loadingController: LoadingController,
        public toastController: ToastController,
        public storage: StorageService,
        private route: ActivatedRoute,
        @Optional() private auth: Auth
    ) {
        this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        this.isCapacitor = this.platform.is("capacitor");
        console.log(`this.isSafari: ${this.isSafari} | this.isCapacitor: ${this.isCapacitor}`);

        this.route.queryParams.subscribe((params: Params) => {
            this.returnUrl = params?.returnUrl || null;
        });
        this.checkIfSocialLoggedIn();
    }

    public get isLoggedIn(): Observable<boolean> {
        return of(!!this.storage.get("user")?.id);
    }

    public async showLoader() {
        if (this.loader === null) {
            this.loader = await this.loadingController.create({
                message: 'Please wait...'
            });
        }
        await this.loader.present();
    }

    public async hideLoader() {
        await this.loader.dismiss();
    }

    public async messageToast(header: string = "Error", message: string = "Something went wrong", color: Color = "danger") {
        const toast = await this.toastController.create({
            header,
            color,
            message,
            duration: 6000
        });
        await toast.present();
    }

    public async loginModal() {
        return this.modalController.create({
            component: LoginModal,
            cssClass: "login-modal",
            keyboardClose: true,
            showBackdrop: true,
            animated: true
        });
    }

    public async socialLogin(provider: SocialProviders) {
        try {
            switch (provider) {
                case "google.com":
                    if (this.isCapacitor) {
                        await FirebaseAuthentication.signInWithGoogle();
                    } else {
                        if (this.isSafari) {
                            await signInWithRedirect(this.auth, new GoogleAuthProvider());
                        } else {
                            await signInWithPopup(this.auth, new GoogleAuthProvider());
                        }
                    }
                    break;
                case "facebook.com":
                    if (this.isCapacitor) {
                        await FirebaseAuthentication.signInWithFacebook();
                    } else {
                        if (this.isSafari) {
                            await signInWithRedirect(this.auth, new FacebookAuthProvider());
                        } else {
                            await signInWithPopup(this.auth, new FacebookAuthProvider());
                        }
                    }
                    break;
                case "apple.com":
                    if (this.isCapacitor) {
                        await FirebaseAuthentication.signInWithApple();
                    } else {
                        if (this.isSafari) {
                            await signInWithRedirect(this.auth, new OAuthProvider('apple.com'));
                        } else {
                            await signInWithPopup(this.auth, new OAuthProvider('apple.com'));
                        }
                    }
                    break;
            }
            return await this.afterSocialLogin();
        } catch (e) {
            return {success: false, message: e};
        }
    }

    public async afterSocialLogin() {
        try {
            const response = await this.loginFromSocialAuthToken();
            await this.handleLoginOrRegisterResponse(response);
            return {success: true, message: ""};
        } catch (e) {
            return {success: false, message: e};
        }
    }

    public async logOut() {
        try {
            this.http.get<IResponse>(this.logoutUrl).toPromise();

            if (this.isCapacitor) {
                await FirebaseAuthentication.signOut();
            } else {
                await signOut(this.auth);
            }
        } catch (e) {
            console.log(e.message);
        }
        this.clearLocalStorage();
    }

    public clearLocalStorage() {
        this.storage.remove("token");
        this.storage.remove("vehicles");
        this.storage.remove("user");
        this.storage.remove("profile");
        this.storage.remove("chats");
        this.storage.remove("favorites");
    }

    private async socialTokenId(): Promise<string> {
        let result;
        if (this.isCapacitor) {
            result = await FirebaseAuthentication.getIdToken();
        } else {
            // @ts-ignore
            result = await idToken(this.auth).pipe(take(1) as any).toPromise();
        }
        return result?.token || result;
    }

    private async loginFromSocialAuthToken(): Promise<IResponse> {
        try {
            const token = await this.socialTokenId();
            if (token) {
                return await this.http.get<IResponse>(this.loginUrl, {
                    headers: {Authorization: `Bearer ${token}`}
                }).toPromise();
            }
            return null;
        } catch (e) {
            return Promise.resolve({success: false, message: e?.error?.message || e?.message});
        }
    }

    private async checkIfSocialLoggedIn() {
        try {
            this.checkingAuthState = true;
            this.isLoggedIn.subscribe(async isLoggedIn => {
                try {
                    if (!isLoggedIn) {
                        const response = await this.loginFromSocialAuthToken();
                        if (response) {
                            await this.handleLoginOrRegisterResponse(response);
                        }
                    }
                } catch (e) {
                    console.log(e.message);
                }
                this.checkingAuthState = false;
            });
        } catch (e) {
            console.log(e.message);
        }
    }

    public checkProfile() {
        const vehicles = this.storage.get("vehicles");
        const profile = this.storage.get("profile");
        const user = this.storage.get("user");

        if (!profile) {
            if (!vehicles?.length && user?.companies?.length) {
                this.setProfile(user.companies[0], "company");
            } else {
                this.setProfile(user, "user");
            }
        } else {
            switch (profile?.kind) {
                case "user":
                    this.setProfile(user, "user");
                    break;
                case "company":
                    const company = user?.companies?.find(c => c?.id === profile?.id);
                    if (company) {
                        this.setProfile(company, "company");
                    } else {
                        this.setProfile(user, "user");
                    }
                    break;
            }
        }
    }

    public setProfile(data: any, kind: "user"|"company") {
        this.storage.set("profile", {...cloneDeep(data), kind});
    }

    public async login(data: IAuthData) {
        try {
            const response = await this.http.post<IResponse>(this.loginUrl, data).toPromise();
            await this.handleLoginOrRegisterResponse(response);
            return {success: true, message: ""};
        } catch (e) {
            await this.handleLoginOrRegisterResponse({success: false, message: e?.error?.message || e?.message});
            return {success: false, message: e?.error?.message || e?.message};
        }
    }

    public async register(data: IAuthData) {
        try {
            const response = await this.http.post<IResponse>(this.registerUrl, data).toPromise();
            await this.handleLoginOrRegisterResponse(response);
            return {success: true, message: ""};
        } catch (e) {
            await this.handleLoginOrRegisterResponse({success: false, message: e?.error?.message || e?.message});
            return {success: false, message: e?.error?.message || e?.message};
        }
    }

    public async resetPassword(data: IAuthData) {
        try {
            const response = await this.http.post<IResponse>(this.resetPasswordUrl, data).toPromise();
            if (response?.success) {
                return {success: true, message: ""};
            }
        } catch (e) {
            await this.handleLoginOrRegisterResponse({success: false, message: e?.error?.message || e?.message});
            return {success: false, message: e?.error?.message || e?.message};
        }
    }

    public async handleLoginOrRegisterResponse(response: IResponse) {
        if (response?.success && response.data?.token) {
            this.clearLocalStorage();
            this.storage.set("token", response.data.token);
            this.storage.set("user", response.data.user);
            this.checkProfile();
        } else {
            this.messageToast("Error", response?.message);
        }

        const redirectRoutes = [
            "/profile/login"
        ];
        if (redirectRoutes.some(rr => this.router.url.includes("/profile/login"))) {
            await this.router.navigate([this.returnUrl || '/profile']);
        }
    }

    public async findUserWithToken(resetToken: string) {
        try {
            const response = await this.http.get<IResponse>(`${environment.api}/auth/new-password/${resetToken}`).toPromise();
            if (response?.success) {
                return {success: true, message: "", user: response.data};
            }
        } catch (e) {
            return {success: false, message: e?.error?.message || e?.message};
        }
    }

    public async newPassword(data: Partial<IUser>) {
        try {
            const response = await this.http.post<IResponse>(this.newPasswordUrl, data).toPromise();
            if (response?.success) {
                return {success: true, message: ""};
            }
        } catch (e) {
            return {success: false, message: e?.error?.message || e?.message};
        }
    }

    public connectPartner(token: string): Promise<IResponse> {
        try {
            return this.http.get<IResponse>(this.connectUrl + "/" + token).toPromise();
        } catch (e) {
            return Promise.resolve({success: false, message: e?.error?.message || e?.message});
        }
    }

    public approvePartnerConnect(token: string, data: any): Promise<IResponse> {
        try {
            return this.http.put<IResponse>(this.connectUrl + "/" + token, data).toPromise();
        } catch (e) {
            return Promise.resolve({success: false, message: e?.error?.message || e?.message});
        }
    }

}
