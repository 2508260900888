import {ErrorHandler, NgModule, LOCALE_ID} from "@angular/core";
import {BrowserModule, provideClientHydration} from "@angular/platform-browser";
import {IonicModule} from "@ionic/angular";
import {StatusBar} from "@ionic-native/status-bar/ngx";
import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {CommonModule, registerLocaleData} from "@angular/common";
import localeEn from "@angular/common/locales/en";
import localeEl from "@angular/common/locales/el";
import localeDe from "@angular/common/locales/de";
import {FormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {environment} from "../environments/environment";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AuthInterceptor} from "./interceptors/auth.interceptor";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {Device} from "@capacitor/device";
import {RollbarErrorHandler, rollbarFactory, RollbarService} from "./services/rollbar.service";
import {LazyLoadImageModule} from "ng-lazyload-image";
import {GumletModule} from "./pipes/gumlet/gumlet.module";
import {NgxPaginationModule} from "ngx-pagination";
import {AuthGuard} from "./guards/auth.guard";
import {LoginGuard} from "./guards/login.guard";
import {NgxGoogleAnalyticsModule} from "ngx-google-analytics";
import {NgxPicaModule} from "@digitalascetic/ngx-pica";
import {NgxStripeModule} from "ngx-stripe";
import {UrlRedirectComponent} from "./components/url-redirect/url-redirect.component";
import moment from 'moment';
import 'moment/locale/el';
import {provideClarity} from "ngx-clarity";
import {StorageService} from "./services/storage.service";
import GBStorage from "./classes/GBStorage";
import {PixelModule} from "ngx-multi-pixel";
import {DISQUS_SHORTNAME} from "ngx-disqus";
import {initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {getAuth, provideAuth} from "@angular/fire/auth";
import {getMessaging, provideMessaging} from "@angular/fire/messaging";
import {getAnalytics, provideAnalytics} from "@angular/fire/analytics";
import {TimeagoModule} from "ngx-timeago";
// import {ServiceWorkerModule} from "@angular/service-worker";
// import {SortablejsModule} from "ngx-sortablejs";

function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json?v=" + Math.round(Date.now() / 1000));
}

function getLocale(lang?: string): {
    lang: string;
    code: string;
    obj: any;
} {
    if (!lang) {
        const storage = GBStorage.instance();
        lang = storage.get("lang");
    }
    let locale;
    switch (lang) {
        case "en":
            locale = {
                lang: "en",
                code: "en-US",
                obj: localeEn
            };
            break;
        case "de":
            locale = {
                lang: "de",
                code: "de-DE",
                obj: localeDe
            };
            break;
        default:
            locale = {
                lang: "el",
                code: "el-GR",
                obj: localeEl
            };
    }
    return locale;
}

@NgModule({
    declarations: [
        AppComponent,
        UrlRedirectComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'GarageBitServerApp'}),
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        IonicModule.forRoot({
            mode: "md",
            scrollPadding: true,
            scrollAssist: true,
        }),
        TranslateModule.forRoot({
            defaultLanguage: "el",
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAnalytics(() => getAnalytics()),
        provideMessaging(() => getMessaging()),
        provideAuth(() => getAuth()),
        PixelModule.forRoot({
            enabled: true,
            pixelId: [environment.analytics.facebookPixelId]
        }),
        NgxGoogleAnalyticsModule.forRoot(environment.analytics.googleTrackingId),
        // ServiceWorkerModule.register("ngsw-worker.js", {
        //     enabled: environment.sw
        // }),
        NgxPaginationModule,
        LazyLoadImageModule,
        NgxStripeModule.forRoot(environment.payments.stripe.key),
        GumletModule,
        NgxPicaModule,
        // SortablejsModule.forRoot({animation: 150})
        TimeagoModule.forRoot()
    ],
    providers: [
        StatusBar,
        AuthGuard,
        LoginGuard,
        {provide: Window, useValue: {}},
        {provide: DISQUS_SHORTNAME, useValue: 'garagebit'},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {
            provide: LOCALE_ID, useValue: getLocale().code
        },
        ...(environment.production ? [
            {provide: ErrorHandler, useClass: RollbarErrorHandler},
            {provide: RollbarService, useFactory: rollbarFactory}
        ] : []),
        provideClarity({
            enabled: environment.analytics.microsoftClarityEnabled,
            projectId: environment.analytics.microsoftClarityProjectId
        }),
        provideClientHydration()
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        private translate: TranslateService,
        private storage: StorageService,
        private window: Window
    ) {
        this.languageSetup();
    }

    public async languageSetup() {
        // Search firstly for query param lang ex: ?lang=en
        const QueryParams = new URLSearchParams(this.window?.location?.search || {});
        const hasQueryLang = environment.languages.includes(QueryParams.get("lang"));
        if (hasQueryLang) {
            this.storage.set("lang", QueryParams.get("lang"));
        }

        let lang = this.storage.get("lang");
        if (!hasQueryLang && !lang) {
            lang = environment.defaultLang;
            try {
                const deviceLanguage = await Device.getLanguageCode();
                const parts = deviceLanguage?.value?.split("-") || [];
                if (environment.languages.includes(parts[0])) {
                    lang = parts[0];
                }
            } catch (e) { /* Nothing */
            }
            this.storage.set("lang", lang);
        }

        // Set NgxTranslate default Locale
        this.translate.setDefaultLang(lang);
        this.setLanguage(lang);

        // Check for language changes
        this.storage.observe("lang").subscribe(lng => {
            this.setLanguage(lng);
        });
    }

    public setLanguage(lang: string) {
        this.translate.use(lang);
        moment.locale(lang);

        const locale = getLocale(lang);
        registerLocaleData(locale.obj);
    }
}
