import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {IResponse} from '../interfaces/IResponse';
import {IBounds, IGetNearByOptions, ILocation} from '../interfaces/ILocation';
import {IVehicleSaleSearch} from "../interfaces/IVehicleSaleSearch";
import {IVehicleSale} from "../interfaces/IVehicleSale";
import {Observable} from "rxjs";
import {IStripeCheckData} from "../interfaces/ISubscription";
import {StorageService} from "./storage.service";
// import {PusherService} from "./pusher.service";

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(
        private http: HttpClient,
        private storage: StorageService,
        // private pusher: PusherService
    ) {}

    public getNearByData(type: string, data: Partial<IGetNearByOptions>) {
        const postData: any = {};
        if (data?.bounds) {
            postData.bounds = this.makeRequestBounds(data.bounds);
        } else {
            postData.bounds = {
                LatMin: 0,
                LatMax: 0,
                LngMin: 0,
                LngMax: 0
            };
        }
        if (data?.filters?.categories) {
            if (!postData.filters) {
                postData.filters = {};
            }
            postData.filters.categories = data.filters.categories;
        }
        return this.http.post<IResponse>(environment.api + "/near-me/" + type, postData);
    }

    public getContinents() {
        return this.http.get<IResponse>(environment.api + "/continents");
    }
    public getCountries(continent_id?: number) {
        return this.http.get<IResponse>(environment.api + "/countries" + (continent_id ? `/${continent_id}` : ""));
    }
    public getCities(country_id) {
        return this.http.get<IResponse>(environment.api + "/countries/" + country_id + "/cities");
    }
    public filterCountries() {
        return this.http.get<IResponse>(environment.api + "/filters/countries");
    }
    public filterCities(country_id) {
        return this.http.get<IResponse>(environment.api + "/filters/countries/" + country_id + "/cities");
    }

    public updateDeviceToken(new_token, old_token?: string) {
        return this.http.put<IResponse>(environment.api + "/device-token", {new_token, old_token});
    }
    public deleteDeviceToken(deviceToken) {
        return this.http.delete<IResponse>(environment.api + "/device-token", {params: {deviceToken}});
    }

    public getLatestSales(filters: any) {
        return this.http.get<IResponse>(environment.api + "/vehicles/sales/latest", {
            params: filters
        });
    }

    public getCompany(id: number) {
        return this.http.get<IResponse>(environment.api + "/company/" + id);
    }

    public upload(folder: string, file: any, options?: any): Observable<any> {
        const formData = new FormData();
        formData.append('folder', folder);
        formData.append('file', file);
        const httpOptions: any = {reportProgress: true};
        if (options?.progressEvents) {
            httpOptions.observe = "events";
        }
        return this.http.post(environment.api + "/upload", formData, httpOptions);
    }

    public panicRequest(data: { image: string, location: ILocation, user_id: string | any, to: "police" | "insurance" | "contact" }) {
        return this.http.post<IResponse>(environment.api + "/panic", data);
    }

    private makeRequestBounds(bounds: IBounds) {
        return {
            LatMin: bounds.lat.min,
            LatMax: bounds.lat.max,
            LngMin: bounds.lng.min,
            LngMax: bounds.lng.max
        };
    }

    public getVehicle(vehicleId) {
        return this.http.get<IResponse>(environment.api + "/vehicles/" + vehicleId);
    }

    public getVehicleSale(id) {
        return this.http.get<IResponse>(environment.api + "/vehicles/sales/view/" + id);
    }

    public getVehicleSaleForEdit(id) {
        return this.http.get<IResponse>(environment.api + "/vehicles/sales/" + id);
    }

    public deleteVehicle(vehicleId) {
        return this.http.delete<IResponse>(environment.api + "/vehicles/" + vehicleId);
    }

    public deleteVehicleSale(id) {
        return this.http.delete<IResponse>(environment.api + "/vehicles/sales/" + id);
    }

    public getVehicleMakeModels(makes: string[]) {
        return this.http.post<IResponse>(environment.api + "/vehicles/makes/models", {makes});
    }

    public getMinMaxPrice(data) {
        return this.http.post<IResponse>(environment.api + "/vehicles/prices", data);
    }

    public getVehicleModelGenerations(modelId) {
        return this.http.get<IResponse>(environment.api + "/vehicles/models/" + modelId + "/generations");
    }

    public getVehicleGenerationVersions(generationId) {
        return this.http.get<IResponse>(environment.api + "/vehicles/generations/" + generationId + "/versions");
    }

    public carDetection(data: any) {
        return this.http.post<IResponse>(environment.api + "/car-recognition", data, {reportProgress: true});
    }

    public createVehicle(data: any) {
        return this.http.post<IResponse>(environment.api + "/vehicles", data);
    }

    public createVehicleSale(data: any) {
        return this.http.post<IResponse>(environment.api + "/vehicles/sales", data);
    }

    public updateVehicleSale(data: any, id) {
        return this.http.put<IResponse>(environment.api + "/vehicles/sales/" + id, data);
    }

    public getPriceHistory(id) {
        return this.http.get<IResponse>(environment.api + "/vehicles/sales/price/history/" + id);
    }

    public vehicleSalePriceReaction(type: "like" | "dislike", priceId: number, vehicleSaleId: number) {
        return this.http.post<IResponse>(environment.api + "/vehicles/sales/price/reaction", {
            type,
            vehicle_sale_price_id: priceId,
            vehicle_sale_id: vehicleSaleId
        });
    }

    public updateVehicle(data: any, vehicleId: string) {
        return this.http.put<IResponse>(environment.api + "/vehicles/" + vehicleId, data);
    }

    public postBook(data: any) {
        return this.http.post<IResponse>(environment.api + "/book-appointment", data);
    }

    public subscribeDeviceTokensToTopic(device_tokens: string[], topic: string) {
        return this.http.post<IResponse>(environment.api + "/subscribe-tokens-to-topic", {
            device_tokens, topic
        });
    }

    public search(key: string) {
        return this.http.get<IResponse>(environment.api + "/live-search", {
            params: { key }
        });
    }

    public connectWithCompany(data) {
        return this.http.post<IResponse>(environment.api + "/connect", data);
    }

    public searchUsers(key: string) {
        return this.http.get<IResponse>(environment.api + "/user/search", {
            params: {key}
        });
    }

    public addVehicleUsers(data) {
        return this.http.post<IResponse>(environment.api + "/vehicles/add-users", data);
    }

    public removeVehicleUser(data) {
        return this.http.post<IResponse>(environment.api + "/vehicles/remove-user", data);
    }

    public loadReviewData(companyId: number, transactionId: number) {
        return this.http.get<IResponse>(environment.api + "/reviews/" + companyId + "/" + transactionId);
    }

    public getUser(userId: number) {
        return this.http.get<IResponse>(environment.api + "/users/" + userId);
    }

    public createReview(companyId: number, transactionId: number, data: any) {
        return this.http.post<IResponse>(environment.api + "/reviews/" + companyId + "/" + transactionId, data);
    }

    public searchVehicleSales(filters: IVehicleSaleSearch) {
        return this.http.post<IResponse>(environment.api + "/vehicles/sales/search", filters);
    }

    public searchUserVehicleSales(filters) {
        return this.http.post<IResponse>(environment.api + "/vehicles/sales/user/search", filters);
    }

    public toggleFavoriteVehicleSale(sale: IVehicleSale) {
        return this.http.get<IResponse>(environment.api + "/vehicles/sales/favorites/toggle/" + sale.id);
    }

    public loadVehicleSalesExtras() {
        return this.http.get<IResponse>(environment.api + "/vehicles/extras");
    }

    public getVehicleSearchStories() {
        return this.http.get<IResponse>(environment.api + "/vehicles/sales/stories");
    }

    public startMassInsertFromFile(url: string) {
        return this.http.post(environment.api + "/vehicles/mass-insert", {
            file: url
        });
    }

    public getChatMessages(chatId) {
        return this.http.get<IResponse>(environment.api + "/chats/" + chatId);
    }
    public sendNewChatMessage(chatId, data) {
        return this.http.post<IResponse>(environment.api + "/chats/" + chatId, data);
    }

    /**
     * ***************** Subscriptions *****************
     */
    public getSubscriptions() {
        return this.http.get<IResponse>(environment.api + "/subscriptions");
    }

    /**
     * ***************** PayPal *****************
     */
    public payWithPayPal(data) {
        return this.http.post<IResponse>(environment.api + "/payments/paypal/create", data);
    }
    public handlePayPalPayment(data) {
        return this.http.post<IResponse>(environment.api + "/payments/paypal/handle", data);
    }
    public subscriptionPayment(data) {
        return this.http.post<IResponse>(environment.api + "/payments/paypal/handle", data);
    }

    /**
     * ***************** Stripe *****************
     */
    public createStripePayment(data) {
        return this.http.post<IResponse>(environment.api + "/payments/stripe/create", data);
    }
    public paymentIntent(data) {
        return this.http.post<IResponse>(environment.api + "/payments/stripe/intent", data);
    }
    public completePaymentOfWallet(data) {
        return this.http.post<IResponse>(environment.api + "/payments/stripe/wallet-complete", data);
    }
    public stripeCheckPayment(data: IStripeCheckData) {
        return this.http.post<IResponse>(environment.api + "/payments/stripe/check", data);
    }


    /**
     * Loaders
     */
    public loadersWatcher() {
        // this.pusher.channelLoaders.bind('load', data => {
        //     this.load(data?.name);
        // });
    }

    public load(name: string, customData?: any) {
        switch (name) {
            case "geo":
                return this.loadGeo();
            case "config":
                return this.loadConfig();
            case "categories":
                return this.loadCategories();
            case "makes":
                return this.loadVehicleMakes(customData);
            case "types":
                return this.loadVehicleTypes();
            case "colors":
                return this.loadVehicleColors();
            case "fuels":
                return this.loadVehicleFuels();
            case "favorites":
                return this.loadFavoriteVehicleSales();
            case "vehicles":
                return this.loadVehicles();
            case "vehicle_categories":
                return this.loadVehicleCategories();
            case "interior_types":
                return this.loadInteriorTypes();
            case "euroclass":
                return this.loadEuroclass();
            case "finance_types":
                return this.loadFinanceTypes();
            case "chats":
                return this.loadChats();
        }
    }

    public async loadConfig() {
        try {
            const response = await this.http.get<IResponse>(environment.api + "/config").toPromise();
            if (response?.success) {
                this.storage.set("config", response.data);
            }
            return response?.data || null;
        } catch (e) {
            return null;
        }
    }

    public async loadGeo() {
        try {
            const response = await this.http.get<IResponse>(environment.api + "/geo").toPromise();
            if (response?.success) {
                this.storage.set("geo", response.data);
            }
            return response?.data || null;
        } catch (e) {
            return null;
        }
    }

    public async loadCategories() {
        try {
            const response = await this.http.get<IResponse>(environment.api + "/categories").toPromise();
            if (response?.success) {
                this.storage.set("categories", response.data);
            }
            return response?.data || null;
        } catch (e) {
            return null;
        }
    }

    public async loadVehicleMakes(customData) {
        try {
            const response = await this.http.post<IResponse>(environment.api + "/vehicles/makes", customData).toPromise();
            if (response?.success) {
                this.storage.set("makes", response.data);
            }
            return response?.data || null;
        } catch (e) {
            return null;
        }
    }

    public async loadVehicleTypes() {
        try {
            const response = await this.http.get<IResponse>(environment.api + "/vehicles/types").toPromise();
            if (response?.success) {
                this.storage.set("types", response.data);
            }
            return response?.data || null;
        } catch (e) {
            return null;
        }
    }

    public async loadVehicleColors() {
        try {
            const response = await this.http.get<IResponse>(environment.api + "/vehicles/colors").toPromise();
            if (response?.success) {
                this.storage.set("colors", response.data);
            }
            return response?.data || null;
        } catch (e) {
            return null;
        }
    }

    public async loadVehicleFuels() {
        try {
            const response = await this.http.get<IResponse>(environment.api + "/vehicles/fuels").toPromise();
            if (response?.success) {
                this.storage.set("fuels", response.data);
            }
            return response?.data || null;
        } catch (e) {
            return null;
        }
    }

    public async loadVehicles() {
        try {
            const response = await this.http.get<IResponse>(environment.api + "/vehicles").toPromise();
            if (response?.success) {
                this.storage.set("vehicles", response.data);
            }
            return response?.data || null;
        } catch (e) {
            return null;
        }
    }

    public async loadFavoriteVehicleSales() {
        try {
            const response = await this.http.get<IResponse>(environment.api + "/vehicles/sales/favorites").toPromise();
            if (response?.success) {
                this.storage.set("favorites", response.data);
            }
            return response?.data || null;
        } catch (e) {
            return null;
        }
    }

    public async loadVehicleCategories() {
        try {
            const response = await this.http.get<IResponse>(environment.api + "/vehicles/vehicle-categories").toPromise();
            if (response?.success) {
                this.storage.set("vehicle_categories", response.data);
            }
            return response?.data || null;
        } catch (e) {
            return null;
        }
    }

    public async loadInteriorTypes() {
        try {
            const response = await this.http.get<IResponse>(environment.api + "/vehicles/interior-types").toPromise();
            if (response?.success) {
                this.storage.set("interior_types", response.data);
            }
            return response?.data || null;
        } catch (e) {
            return null;
        }
    }

    public async loadEuroclass() {
        try {
            const response = await this.http.get<IResponse>(environment.api + "/vehicles/euroclass").toPromise();
            if (response?.success) {
                this.storage.set("euroclass", response.data);
            }
            return response?.data || null;
        } catch (e) {
            return null;
        }
    }

    public async loadFinanceTypes() {
        try {
            const response = await this.http.get<IResponse>(environment.api + "/vehicles/finance-types").toPromise();
            if (response?.success) {
                this.storage.set("finance_types", response.data);
            }
            return response?.data || null;
        } catch (e) {
            return null;
        }
    }

    public async loadChats() {
        try {
            const response = await this.http.get<IResponse>(environment.api + "/chats").toPromise();
            if (response?.success) {
                this.storage.set("chats", response.data);
            }
            return response?.data || null;
        } catch (e) {
            return null;
        }
    }
}
