import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ILocation} from "../interfaces/ILocation";
import {Geolocation} from "@capacitor/geolocation";
import {environment} from "../../environments/environment";
import {StorageService} from "./storage.service";

@Injectable({
    providedIn: 'root'
})
export class GeolocationService {
    public location: BehaviorSubject<ILocation> = new BehaviorSubject(null);
    public locationSync: ILocation = null;

    constructor(private storage: StorageService) {
        const loc = this.storage.get("location");
        if (loc?.lat && loc?.lng) {
            this.setCoords(loc.lat, loc.lng);
        }
    }

    public static formatNumber(num: string | number) {
        if (typeof num === "string") {
            num = parseFloat(num);
        }
        return parseFloat(num.toFixed(6));
    }

    async requestPosition() {
        try {
            const resp = await Geolocation.getCurrentPosition();
            this.setCoords(resp?.coords?.latitude, resp?.coords?.longitude);

            Geolocation.watchPosition({}, (position, err) => {
                if (!err) {
                    this.setCoords(position?.coords?.latitude, position?.coords?.longitude);
                } else {
                    this.setCoords(environment.maps.center.lat, environment.maps.center.lng);
                    console.log('Error watching location', err);
                }
            });
        } catch (error) {
            this.setCoords(environment.maps.center.lat, environment.maps.center.lng);
            console.log('Error getting location', error);
        }
    }

    private setCoords(lat, lng) {
        if (!this.isLocation({lat, lng})) {
            // maybe do something.
            return;
        }
        const coords = {
            lat: GeolocationService.formatNumber(lat),
            lng: GeolocationService.formatNumber(lng)
        };

        if (JSON.stringify(this.locationSync) === JSON.stringify(coords)) {
            return;
        }

        this.storage.set("location", coords);
        this.location.next(coords);
        this.locationSync = coords;
    }

    public isLocation(location: ILocation): boolean {
        return !!(location?.lat && location?.lng);
    }

    public getDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
        if (!lat1 || !lon1 || !lat2 || !lon2) { return 0; }
        const R = 6371; // Radius of the earth in km
        const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
        const dLon = this.deg2rad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distance in km
    }

    public deg2rad(deg): number {
        return deg * (Math.PI / 180);
    }
}
