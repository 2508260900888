import {Component} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {ApiService} from "../../../services/api.service";

@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.html',
    styleUrls: ['./login-modal.scss'],
})
export class LoginModal {

    public authTab: string = "login";

    constructor(
        public modalCtrl: ModalController,
        public api: ApiService
    ) {
    }

    public async dismiss() {
        await this.modalCtrl.dismiss(null, "success");
    }

}
