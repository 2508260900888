import {Injectable} from '@angular/core';
import {IChat, IChatCreate, IChatStorage, IMessage} from "../interfaces/IChat";
import {IProfile} from "../interfaces/IProfile";
import {ApiService} from "./api.service";
import {IResponse} from "../interfaces/IResponse";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {SuperStorage} from "../decorators/storage";

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    @SuperStorage("chats", {user: [], companies: {}})
    public chatStorage: IChatStorage;

    @SuperStorage("profile", {})
    public profile: IProfile;

    public chats: IChat[] = [];
    public unreadChats: number = 0;

    constructor(
        private api: ApiService,
        private http: HttpClient,
        private storage: StorageService,
    ) {
        this.storage.observe("profile").subscribe(() => this.reloadChats());
    }

    public async reloadChats() {
        if (!this.profile?.id) {
            return;
        }
        this.setChats();
        await this.api.load("chats");
        this.setChats();
    }

    public setChats() {
        switch (this.profile?.kind) {
            case "user":
                this.chats = this.chatStorage?.user || [];
                break;
            case "company":
                this.chats = this.chatStorage?.companies?.[this.profile?.id] || [];
                break;
        }
        this.make();
    }

    public make() {
        this.unreadChats = 0;
        for (const chat of this.chats) {
            chat.me = chat.participants.find(p => {
                const pattern = new RegExp(this.profile?.kind, "i");
                return p.sender_id === this.profile?.id && pattern.test(p.sender_type);
            });
            chat.unread = chat.me?.last_read < chat.messages?.[0]?.created_at;
            if (chat.unread) {
                this.unreadChats++;
            }
        }
    }

    public async sendMessage(chatId, data: Partial<IMessage>): Promise<IResponse> {
        return new Promise((resolve) => {
            this.api.sendNewChatMessage(chatId, data)
                .subscribe(
                    (response) => {
                        resolve(response);
                    },
                    (error) => {
                        resolve({
                            success: false,
                            message: error.message
                        });
                    }
                );
        })
    }

    public create(data: IChatCreate) {
        return this.http.post<IResponse>(environment.api + "/chats", data);
    }

    public lastRead(chatId, last_read) {
        return this.http.put<IResponse>(environment.api + "/chats/" + chatId + "/last-read", {last_read});
    }
}
