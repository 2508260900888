import Rollbar from "rollbar";
import {ErrorHandler, Inject, Injectable, InjectionToken} from "@angular/core";
import {environment} from "../../environments/environment";
import {IProfile} from "../interfaces/IProfile";
import {SuperStorage} from "../decorators/storage";

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {

    @SuperStorage("profile")
    public profile: IProfile;

    constructor(
        @Inject(RollbarService) private rollbar: Rollbar,
        private window: Window
    ) {}

    handleError(err: any): void {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(err.message)) {
            this.window?.location.reload();
        } else {
            this.rollbar.error(err.originalError || err);
        }
    }
}

export function rollbarFactory() {
    const profileData: any = {};
    if (this.profile?.email) {
        profileData.captureEmail = this.profile.email;
        profileData.captureUsername = this.profile?.title || this.profile?.name || "Unknown username";
    }
    return new Rollbar({
        ...environment.rollbar,
        ...profileData,
        captureUncaught: true,
        captureUnhandledRejections: true
    });
}

export const RollbarService = new InjectionToken<Rollbar>("rollbar");
