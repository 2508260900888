import {Injectable} from '@angular/core';
import {IBanners} from "../interfaces/general";
import {catchError, of} from "rxjs";
import {StorageService} from "./storage.service";
import {SuperStorage} from "../decorators/storage";

@Injectable({
    providedIn: 'root'
})
export class HelpersService {

    @SuperStorage("banners", {})
    public banners: IBanners;

    constructor(
        private storage: StorageService
    ) {}

    public get closedBanners() {
        const closed = {};
        const now = Date.now();
        for (const key in this.banners) {
            if ((now - this.banners[key].timestamp) < this.banners[key].limit) {
                closed[key] = true;
            }
        }
        return closed;
    }

    /**
     * @param key the id of banner
     * @param limit 30 Days => 60 * 60 * 24 * 30 * 1000 = 2592000000 millis
     */
    public closeBanner(key: string, limit: number = 2592000000) {
        this.banners[key] = {
            timestamp: Date.now(),
            limit
        };
        this.banners = this.banners;
    }

    public apiCatchError() {
        return catchError(({error}) => {
            return of(error);
        })
    }

    public multiLang(value: any, prop?: string): any {
        const lang = this.storage.get("lang");
        if (!value) {
            return;
        }
        if (!Array.isArray(value)) {
            if (lang && value[lang]) {
                return value[lang];
            }
            if (value["en"]) {
                return value["en"]
            }
            return value;
        } else {
            if (!prop) {
                prop = "name";
            }
            return value.map(v => {
                if (lang && v[prop][lang]) {
                    v[prop] = v[prop][lang];
                }
                return v;
            });
        }
    }
}
