import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {StorageService} from "../services/storage.service";

@Injectable({
    providedIn: 'root'
})
export class RootGuard  {

    constructor(
        private router: Router,
        private storage: StorageService
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const firstLang = this.storage.get("lang") || environment.defaultLang;
        this.router.navigate(["/" + firstLang + "/" + environment.homepage]);
        return false;
    }

}
