import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {AuthService} from "../services/auth.service";
import {GeolocationService} from "../services/geolocation.service";
import {ILocation} from "../interfaces/ILocation";
import {catchError, throwError} from "rxjs";
import {Router} from "@angular/router";
import {LoadingService} from "../services/loader.service";
import {map} from "rxjs/operators";
import {IProfile} from "../interfaces/IProfile";
import {EchoService} from "../services/echo.service";
import {StorageService} from "../services/storage.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private auth: AuthService,
        private geo: GeolocationService,
        private router: Router,
        private loading: LoadingService,
        private storage: StorageService,
        private echo: EchoService
    ) {
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ) {
        this.loading.setLoading(true, req.url);

        const token: string = this.storage.get("token");
        const deviceToken: string = this.storage.get("device-token");
        const debug: string = this.storage.get("debug");
        const language: string = this.storage.get("lang");
        const profile: IProfile = this.storage.get("profile");
        const location: ILocation = this.storage.get("location");

        let locationString = "";
        if (location?.lat) {
            locationString = location.lat + "," + location.lng;
        }

        if (token) {
            req = this._addHeader(req, "Authorization", `Bearer ${token}`);
        }

        if (language) {
            req = this._addHeader(req, "Content-Language", language);
        }

        if (locationString) {
            req = this._addHeader(req, "x-location", locationString);
        }

        if (deviceToken) {
            req = this._addHeader(req, "x-device-token", deviceToken);
        }

        if (debug) {
            req = this._addHeader(req, "x-debug", "true");
        }

        if (profile) {
            req = this._addHeader(req, "x-profile", `${profile.kind}:${profile.id}`);
        }

        if (this.echo.echo?.socketId()) {
            req = this._addHeader(req, "X-Socket-ID", this.echo.echo.socketId());
        }

        req = req.clone({
            withCredentials: true
        });

        return next.handle(req)
            .pipe(
                catchError(response => {
                    this.loading.setLoading(false, req.url);
                    switch (response?.error?.message || "") {
                        case "Token has expired":
                            this.auth.logOut();
                            this.router.navigate(["/profile/login"]);
                            break;
                    }
                    return throwError(response);
                }),
                map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
                    if (evt instanceof HttpResponse) {
                        this.loading.setLoading(false, req.url);
                    }
                    return evt;
                })
            );
    }

    private _addHeader(request: HttpRequest<any>, key: string, value: string) {
        return request.clone({
            setHeaders: {[key]: value}
        });
    }
}
