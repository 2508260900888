import {Injectable} from '@angular/core';
import GBStorage from "../classes/GBStorage";

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    protected _storage: GBStorage;

    constructor() {
        this._storage = GBStorage.instance();
    }

    public set(key: string, value: any) {
        return this._storage.set(key, value);
    }

    public get(key: string) {
        return this._storage.get(key);
    }

    public remove(key: string) {
        return this._storage.remove(key);
    }

    public clear() {
        return this._storage.clear();
    }

    public observe(key: string) {
        return this._storage.observe(key);
    }

}
