import {Injectable} from "@angular/core";
import {Meta, Title} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {ISeoMetaTags} from "../interfaces/general";
import {IGarageBitConfig} from "../interfaces/IGarageBitConfig";
import {HelpersService} from "./helpers.service";
import {SuperStorage} from "../decorators/storage";

@Injectable({
    providedIn: "root"
})
export class SeoService {

    @SuperStorage("config", {})
    public config: IGarageBitConfig;

    constructor(
        private helpers: HelpersService,
        private title: Title,
        private meta: Meta,
        private router: Router,
        private window: Window
    ) {
    }

    public set(data: Partial<ISeoMetaTags> = {}) {
        const color = data.color || "#0089b8";
        const url = data.url || this.window?.location?.origin + this.router.url;
        const title = data.title || this.helpers.multiLang(this.config.seo?.title) || "GarageBit";
        const image = data.image || this.helpers.multiLang(this.config.seo?.image) || "";
        const keywords = data.keywords || this.helpers.multiLang(this.config.seo?.keywords) || "";
        const description = data.description || this.helpers.multiLang(this.config.seo?.description) || "";

        this.title.setTitle(title);
        const tags: any[] = [
            {name: 'keywords', content: keywords},
            {name: 'description', content: description},
            {property: "theme-color", content: color},
            {property: "og:title", content: title},
            {property: "og:url", content: url},
            {property: "og:image", content: image},
            {property: "og:description", content: description},
            {property: "og:type", content: "website"},
            {property: 'image', content: image},
            {name: 'twitter:card', content: "summary_large_image"},
            {name: 'twitter:site', content: "@GarageBit"},
            {name: 'twitter:description', content: description},
            {name: "twitter:title", content: title},
            {name: "twitter:url", content: url},
            {name: "twitter:image", content: image},
            {property: "al:ios:url", content: image},
            {property: "al:ios:app_store_id", content: "1536207684"},
            {property: "al:ios:app_name", content: "GarageBit"},
            {property: "al:android:url", content: "garagebit://"},
            {property: "al:android:app_name", content: "GarageBit"},
            {property: "al:android:package", content: "com.garagebit.app"},
            {property: "al:web:url", content: url},
            {property: "fb:app_id", content: "327931274536809"},
        ];

        if (data.author) {
            tags.push({name: 'author', content: data.author});
        }
        if (data.date) {
            tags.push({name: 'date', content: data.date, scheme: "YYYY-MM-DD"});
        }

        for (const tag of tags) {
            const selector = tag.name !== undefined ? `name='${tag.name}'` : `property='${tag.property}'`;
            if (this.meta.getTag(selector)) {
                this.meta.updateTag(tag, selector);
            } else {
                this.meta.addTag(tag);
            }
        }
    }

}
