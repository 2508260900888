import {NgModule} from '@angular/core';
import {GumletPipe} from "./gumlet.pipe";

@NgModule({
    declarations: [GumletPipe],
    imports: [],
    exports: [GumletPipe]
})
export class GumletModule {
}
