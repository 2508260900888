<div class="text-center">
    <img class="logo mx-3" src="/assets/logo/horizontal-blue.svg" alt="GarageBit"/>
    <div class="alert alert-info p-2 mt-3 mb-1">
        <div translate>You only need to Login to continue.</div>
        <b translate>It's so simple</b> 🚀
    </div>
</div>

<ng-container [ngSwitch]="authTab">
    <ng-container *ngSwitchCase="'register'">
        <app-register-form (login)="authTab = 'login'" [loginRedirect]="false" (registered)="dismiss()"/>
    </ng-container>
    <ng-container *ngSwitchCase="'login'">
        <app-login-form (register)="authTab = 'register'" [registerRedirect]="false" (loggedIn)="dismiss()"/>
    </ng-container>
</ng-container>
<app-social-login-form (loggedIn)="dismiss()"/>
