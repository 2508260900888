import {Component} from '@angular/core';

@Component({
    selector: 'app-url-redirect',
    template: ''
})
export class UrlRedirectComponent {
    constructor() {
    }
}
