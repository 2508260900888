import {NgModule} from "@angular/core";
import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from "@angular/router";
import {environment} from "../environments/environment";
import {UrlRedirectComponent} from "./components/url-redirect/url-redirect.component";
import {MultilangGuard} from "./guards/multilang.guard";
import {RootGuard} from "./guards/root.guard";

const routes: Routes = [
    {
        path: "",
        canActivate: [RootGuard],
        component: UrlRedirectComponent,
        pathMatch: "full"
    },
    {
        path: ":lang",
        canActivate: [MultilangGuard],
        children: [
            {
                path: "",
                loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuPageModule)
            }
        ]
    },
];
const routeExtraOptions: ExtraOptions = {
    scrollPositionRestoration: "enabled",
    initialNavigation: "enabledBlocking"
};
if (environment.name === "capacitor") {
    // routeExtraOptions.preloadingStrategy = PreloadAllModules;
}

@NgModule({
    imports: [
        RouterModule.forRoot(routes, routeExtraOptions)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
