import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {ApiService} from "../services/api.service";
import {StorageService} from "../services/storage.service";

@Injectable({
    providedIn: 'root'
})
export class MultilangGuard {

    constructor(
        private router: Router,
        private storage: StorageService,
        private api: ApiService
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const parts = state.url.slice(1).split("/");
        if (environment.languages.includes(parts[0])) {
            this.storage.set("lang", parts[0]);
            this.api.load("config");
            return true;
        }
        const lang = this.storage.get("lang") || environment.defaultLang;
        this.router.navigateByUrl([lang, ...parts].join("/"), {});
        return false;
    }

}
