import {Injectable} from '@angular/core';
import {share, tap} from "rxjs/operators";
import {Platform} from "@ionic/angular";
import {PushNotifications, ActionPerformed, PushNotificationSchema, Token} from "@capacitor/push-notifications";
import {from, Observable, Subject} from "rxjs";
import {INotification, INotificationAction} from "../interfaces/INotification";
import {ApiService} from "./api.service";
import {IUser} from "../interfaces/IUser";
import {SuperStorage} from "../decorators/storage";
import {isSupported, getMessaging, getToken, deleteToken, onMessage, Messaging} from "@angular/fire/messaging";
import {environment} from "../../environments/environment";
import {getApp} from "@angular/fire/app";

@Injectable({
    providedIn: "root"
})
export class MessagingService {

    public notification$: Subject<INotification> = new Subject<INotification>();
    public notificationAction$: Subject<INotificationAction> = new Subject<INotificationAction>();

    @SuperStorage("device-token")
    public deviceToken: string;

    @SuperStorage("user")
    public user: IUser;

    private messaging: Messaging;
    private token$: Observable<string>;

    constructor(
        private platform: Platform,
        private api: ApiService
    ) {
    }

    public async initializePushNotifications() {
        if (this.platform.is("capacitor")) {
            // @ts-ignore
            PushNotifications.requestPermissions().then((result) => {
                switch (result.receive) {
                    case "granted":
                        PushNotifications.register();
                        break;
                    case "denied":
                    case "prompt":
                    case "prompt-with-rationale":
                        this.onError(result);
                        break;
                }
            });
            PushNotifications.addListener('registration', (token: Token) => {
                this.onSuccess(token.value);
            });
            PushNotifications.addListener('registrationError', this.onError.bind(this));
            PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
                this.notification$.next(notification);
            });
            PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
                this.notificationAction$.next(action);
            });
        } else {
            try {
                if (!(await isSupported())) {
                    return;
                }
                this.initToken();
                this.requestPermission();

                // this.messaging.messages.subscribe((payload: any) => {
                //     this.notification$.next(payload.notification);
                // });
            } catch (e) {
                console.warn(e.message);
            }
        }
    }

    public initToken() {
        try {
            this.messaging = getMessaging(getApp());
            this.token$ = from(
                navigator.serviceWorker.register('firebase-messaging-sw.js', {
                    type: 'module',
                    scope: '__'
                }).then(serviceWorkerRegistration => getToken(this.messaging, {
                    serviceWorkerRegistration,
                    vapidKey: environment.vapidKey,
                }))
            ).pipe(
                tap(token => console.log('FCM', {token})),
                share(),
            );
            onMessage(this.messaging, (p) => {
                this.notification$.next({
                    id: p.messageId,
                    body: p.notification.body,
                    title: p.notification.title,
                    data: p.data
                });
            });
        } catch (e) {
            console.log(e.message);
        }
    }

    public requestPermission() {
        Notification.requestPermission()
            .then(this.onSuccess.bind(this))
            .catch(this.onError.bind(this));
    }

    public onSuccess(result: string) {
        if (result === "granted") {
            this.token$?.subscribe(token => {
                if (this.user?.id) {
                    this.api.updateDeviceToken(token, this.deviceToken).subscribe();
                }
                this.deviceToken = token;
            });
        } else {
            console.log(result);
        }
    }

    public onError(error: any) {
        // console.error(error.message);
    }

    public async deleteToken() {
        if (this.platform.is("capacitor")) {
            await PushNotifications.removeAllListeners();
        } else {
            await deleteToken(this.messaging);
        }
    }

    public subscribeToTopic(topicName: string) {
        if (this.deviceToken) {
            this.api.subscribeDeviceTokensToTopic([this.deviceToken], topicName).subscribe();
        }
    }
}
