import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {map} from 'rxjs/operators';
import {AuthService} from "../services/auth.service";

@Injectable()
export class LoginGuard {

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authService.isLoggedIn.pipe(
            map(user => {
                if (user) {
                    this.router.navigate(['/profile']);
                    return false;
                }
                return true;
            })
        );
    }
}
