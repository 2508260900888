import GBStorage from "../classes/GBStorage";

export function SuperStorage(key?: string, defaultValue?: any) {
    return (prototype, propName) => {
        const rawKey: string = key || propName;
        const storage = GBStorage.instance();
        Object.defineProperty(prototype, propName, {
            get: () => storage?.get(rawKey) || defaultValue,
            set: (v) => storage?.set(rawKey, v)
        });
    };
}
